import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColorPip = _resolveComponent("ColorPip")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["Filter", {active: _ctx.isActive()}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle()))
  }, [
    _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
    (_ctx.colorList?.indexOf('W') >= 0)
      ? (_openBlock(), _createBlock(_component_ColorPip, {
          key: 0,
          color: "W"
        }))
      : _createCommentVNode("", true),
    (_ctx.colorList?.indexOf('U') >= 0)
      ? (_openBlock(), _createBlock(_component_ColorPip, {
          key: 1,
          color: "U"
        }))
      : _createCommentVNode("", true),
    (_ctx.colorList?.indexOf('B') >= 0)
      ? (_openBlock(), _createBlock(_component_ColorPip, {
          key: 2,
          color: "B"
        }))
      : _createCommentVNode("", true),
    (_ctx.colorList?.indexOf('R') >= 0)
      ? (_openBlock(), _createBlock(_component_ColorPip, {
          key: 3,
          color: "R"
        }))
      : _createCommentVNode("", true),
    (_ctx.colorList?.indexOf('G') >= 0)
      ? (_openBlock(), _createBlock(_component_ColorPip, {
          key: 4,
          color: "G"
        }))
      : _createCommentVNode("", true)
  ], 2))
}