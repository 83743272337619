
  import { defineComponent } from 'vue';
  import Card from '@/components/Card.vue';
  import PrintingSelect from '@/components/PrintingSelect.vue';
  import { OrderAttribute, OrderDirection } from '@/classes/scryfallConnector'
  import { state, actions } from "../store";

  export default defineComponent({
    name: 'SearchResults',
    components: {
      Card,
      PrintingSelect
    },
    data() {
      return {
        state: state,
        maxCards: 16
      }
    },
    computed: {
      presentableCards: () => {
        return state.resultList.slice(0, state.displayCardCount);
      }
    },
    mounted() {
      const observer = new IntersectionObserver((entries:Array<IntersectionObserverEntry>) => {
        entries.forEach((entry) => {
          console.log(entry);
          if (entry.intersectionRatio > 0) {
            actions.setDisplayCardCount(state.displayCardCount+32);
          }
        })
      }, {root: null, threshold: 0.5});

      observer.observe(document.querySelector('#intersectionSpot') as Element);

      //(this as any).observer.observe(this);
    },
    methods: {
      setOrderAttribute(e:Event) {
        const attribute = (e.target as HTMLSelectElement).value as OrderAttribute;
        actions.setOrder({
          attribute: attribute,
          direction: state.connector.order.direction
        });
        actions.doQuery();
      },
      setOrderDirection(e:Event) {
        const direction = (e.target as HTMLSelectElement).value as OrderDirection;
        actions.setOrder({
          attribute: state.connector.order.attribute,
          direction: direction
        });
        actions.doQuery();
      },
    }
  });
