
import { defineComponent } from 'vue';
import QuickScroll from '@/components/QuickScroll.vue';
import Divider from '@/components/Divider.vue';
import PrintingSelect from '@/components/PrintingSelect.vue';
import Card from '@/components/Card.vue';
import { landCycleList } from '@/classes/utils';

import { actions, state } from "../store";

export default defineComponent({
  name: 'CycleView',
  data() {
    return {
      state: state
    }
  },
  components: {
    Card,
    QuickScroll,
    Divider,
    PrintingSelect
  },
  mounted: function() {
    actions.setScrollTop();
  },
  methods: {
    getLandCycle: function() {
      return landCycleList.filter(x => x.name === this.$route.params.cycleId)[0];
    },
    cycleCards: function() {
      //$route.params.cycleId
      const cardMap = new Map();
      [...state.connector.allCards.values()].forEach((item) => {
        const key = item?.cycle?.name;
        if(key) {
          const collection = cardMap.get(key);
          if (!collection) {
             cardMap.set(key, [item]);
          } else {
             collection.push(item);
          }
        }
      });

      console.log(cardMap);
      return cardMap.get(this.$route.params.cycleId) ?? [];
    }
  }
});
