
  import { defineComponent } from 'vue';
  import { state, actions } from "../store";

  export default defineComponent({
    name: 'PriceControl',
    components: {
    },
    data() {
      return {
        state: state,
        tmpPrice: state.connector.filters.priceMax,
        priceList: [1,5,15,25,50]
      }
    },
    mounted() {
      console.log(state.connector.filters.priceMax);
      // Old fashioned reset listening.
      document.getElementById('priceControl')?.addEventListener('resetPrice', (resetEvent) => this.setPrice(((resetEvent as CustomEvent).detail!.newPrice)));
    },
    methods: {
      resetPrice: function() {
        console.log('should i reset?');
        if(!state.advancedSearch) {
          console.log('must reset');
          this.setPrice(99999);
        }
        
      },
      setPrice: function(price:number) {
        console.log("time to set price");
        this.tmpPrice = price;
        this.updateTrack(price);

        actions.setPriceFilter(price === this.priceList[this.priceList.length-1] ? 99999 : price);
        actions.doQuery();
      },

      updateDisplay: function(e:Event) {
        const value = Number((e!.target as HTMLInputElement).value);
        this.tmpPrice = value;
        this.updateTrack(value);
      },

      updateTrack: function(price:Number) {
        const maxNum = this.priceList[this.priceList.length-1];
        const el = document.getElementById('rangeInput') as HTMLInputElement;
        el.style.background = 'linear-gradient(to right, #86C232 0%, #86C232 ' + (price as number)/maxNum*100 + '%, #fff ' + (price as number)/maxNum*100 + '%, white 100%)';
      },
      setPriceViaRange: function(e:Event) {
        const value = Number((e!.target as HTMLInputElement).value);
        this.tmpPrice = value;

        actions.setPriceFilter(value === this.priceList[this.priceList.length-1] ? 99999 : value);
        actions.doQuery();
      }
    }
  });
