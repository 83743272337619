import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text font--fancy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["Button", _ctx.type+' '+_ctx.size])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          class: "icon",
          icon: ['far', _ctx.icon]
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true)
  ], 2))
}