import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "PriceControl",
  id: "priceControl"
}
const _hoisted_2 = { class: "priceLabel" }
const _hoisted_3 = { class: "priceContainer" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["max", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.tmpPrice >= _ctx.priceList[_ctx.priceList.length-1] ? '$NO LIMIT' : `$${_ctx.tmpPrice}`), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceList, (price) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["priceDollar", {'active': _ctx.tmpPrice >= price}]),
          onClick: ($event: any) => (_ctx.setPrice(price)),
          key: price
        }, [
          _createVNode(_component_font_awesome_icon, { icon: ['far', 'dollar-sign'] })
        ], 10, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("input", {
      id: "rangeInput",
      type: "range",
      max: _ctx.priceList[_ctx.priceList.length-1],
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateDisplay && _ctx.updateDisplay(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setPriceViaRange && _ctx.setPriceViaRange(...args))),
      min: "1",
      step: "1",
      value: _ctx.tmpPrice
    }, null, 40, _hoisted_5)
  ]))
}