
import { defineComponent } from 'vue';
import { actions, state, getCardFromId } from "../store";
import Card from '@/components/Card.vue';
import Divider from '@/components/Divider.vue';
import ColorPip from '@/components/ColorPip.vue';

export default defineComponent({
  name: 'CardView',
  data() {
    return {
      state: state
    }
  },
  components: {
    Card,
    ColorPip,
    Divider
  },
  methods: {
    getCardData: function(id:string) {
      getCardFromId(id);
    },
    getPrintingsByDate: function() {
      console.log(this.cardData);
      return [...this.cardData!.printings].sort((a,b) => b.set_release.localeCompare(a.set_release))
    },
    setCurrentManaList: function() {

    }
  },
  mounted: function() {
    actions.setScrollTop();
  },
  computed: {
    cardData() { 
      return getCardFromId(this.$route.params.cardId as string) ?? null
    },

    printingId() {
      if(this.$route.params.cardId) {
        return this.$route.params.printingId as string
      } else {
        return null;
      }
      
    }
  }
});
