
  import { defineComponent } from 'vue';
  import { state, actions } from "../store";
  import { ScryfallCard } from '../classes/scryfallCard';
  
  import ColorPip from '@/components/ColorPip.vue';


  export default defineComponent({
    name: 'CardFace',
    props: {
      card: ScryfallCard,
      enableAdd: {
        type: Boolean,
        default: true,
      },
      usePrintingLink: {
        type: Boolean,
        default: false
      },
      showFrame: {
        type: Boolean,
        default: true,
      },
      printing: Object,
      doesListIncludesCard: Boolean,
      face: String
    },
    components: {
      ColorPip
    },
    data() {
      return {
        active: false,
        state: state,
        flipped: false,
        loaded: false,
      }
    },
    computed: {

    },
    methods: {
      onImageLoad: function() {
        this.loaded = true;
      },
      flip: function() {
        this.$emit('flip');
      },
      addCard: function() {

        if(!this.doesListIncludesCard) {
          actions.addCardToManaList(this.$props.card as ScryfallCard, this!.printing!.id);
        } else {
          actions.removeCardFromManaList(this.$props.card as ScryfallCard);
        }

        this.$forceUpdate();        
      },
      getPrinting: function() {
        let loadingMethod = this.printing ? "printingId" : this.state.preferredPrinting;
        let loadingValue = this.printing ? this.printing.id : undefined;

        return this?.card?.getPrinting(loadingMethod, loadingValue)
      },
      imageUrl: function() {
        /*
        const printing = this?.card?.getPrinting(state.preferredPrinting);
        return `${process.env.BASE_URL}/cards/${this.loaded ? 'high' : 'low'}/${this.face}/${printing?.id}.${format}`;
        */

        const printing = this.getPrinting();
        if(this.loaded) {
          return printing!.face_url_list[this.face === 'front' ? 0 : 1];
        } else {
          return printing!.face_url_list_low[this.face === 'front' ? 0 : 1];
        }
      },
    }
  });
