
  import { defineComponent } from 'vue';
  import { state } from "../store";

  export default defineComponent({
    name: 'QuickScroll',
    data() {
      return {
        state: state,
      }
    },
    methods: {
      goTop: function() {
        document.getElementById("main")?.scrollTo(0, 0);
      }
    }
  });
