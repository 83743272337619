
  import { defineComponent } from 'vue';
  import { ScryfallCard } from '@/classes/scryfallCard';
  import { state } from "../store";
  import CardFace from '@/components/CardFace.vue';

  export default defineComponent({
    name: 'Card',
    props: {
      card: ScryfallCard,
      printingId: {
        type: String,
        default: ''
      },
      showPrinting: Boolean,
      showFrame: Boolean,
      enableAdd: {
        type: Boolean,
        default: true
      },
      usePrintingLink: {
        type: Boolean,
        default: false
      },
    },
    components: {
      CardFace
    },
    data() {
      return {
        active: false,
        state: state,
        flipped: false,
      }
    },
    computed: {
      doesListIncludesCard() {
        return state?.currentManaList?.cardList?.has(this?.card?.id ?? '');
      }
    },
    methods: {
      flipFunc: function() {
        this.flipped = !this.flipped;
      },
      getPrinting: function() {
        if(!this.printingId) {
          return this!.card!.getPrinting(state.preferredPrinting)
        } else {
          return this!.card!.getPrinting('printingId', this.printingId)
        }
      }
    }
  });
