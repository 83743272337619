
  import { defineComponent } from 'vue';
  import { state, actions } from "../store";

  export default defineComponent({
    name: 'ColorButton',
    props: {
      color: String,
      clickable: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        state: state
      }
    },
    methods: {
      click: function() {
        if(this.clickable) {
          actions.setColorFilter(this.color as string);
        }
      },
    }
  });
