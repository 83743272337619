
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue';
import Select from '@/components/Select.vue';
import Divider from '@/components/Divider.vue';
import Card from '@/components/Card.vue';
import { ManaList, ManaListEntry } from '@/classes/manaLists';
import { state, actions, getManaList } from "../store";

export default defineComponent({
  name: 'ManaList',
  data() {
    return {
      state: state,
      currentEntry: null as ManaListEntry|null,
      groupCards: 'productionType',
      error: false,
    }
  },
  components: {
    Button,
    Card,
    Divider,
    Select,
  },
  methods: {
    changeGroupings: function(newValue:string) {
      console.log('hi');
      this.groupCards = newValue;
    },
    setCurrentManaList: function() {
      actions.setCurrentManaList(this.$route.params.listId as string);
    }
  },
  created() {
    // Inits the selected card
    if(!this.currentEntry) {
      this.currentEntry = Array.from(this!.pageManaList!.cardList!.values())[0] ?? null;
    }
  },
  computed: {
    pageManaList():ManaList|null {
      return getManaList(this.$route.params.listId as string);
    },

    entriesByProduction():Map<string, Array<ManaListEntry>> {
      return (this!.pageManaList as any).computeCardsByProduction()
    },

    entriesByProductionCount():Map<string, Array<ManaListEntry>> {
      return (this!.pageManaList as any).computeCardsByProductionCount()
      //return (this!.pageManaList as any).computeCardsByProductionCount()
    },

    entriesByTapStatus():Map<string, Array<ManaListEntry>> {
      return (this!.pageManaList as any).computeCardsByTapStatus()
    }
  }
});
