
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'ColorPip',
    props: {
      color: String,
      size: String
    },
    data() {
      return {
      }
    }
  });
