import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "Cycles font--fancy" }
const _hoisted_2 = { class: "cycles--dual" }
const _hoisted_3 = { class: "cycleContainer" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "displayName" }
const _hoisted_6 = { class: "cycles--triple" }
const _hoisted_7 = { class: "cycleContainer" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "displayName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_Divider, {
        text: "Dual Cycles",
        level: "2"
      }),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLandCycles('dual'), (cycle) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "cycleItem",
            key: cycle
          }, [
            _createVNode(_component_router_link, {
              to: {name:'CycleView', params: {cycleId: cycle.key}},
              replace: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "coverArt",
                  src: cycle.coverArt
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, _toDisplayString(cycle.display), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createVNode(_component_Divider, {
        text: "Triple Cycles",
        level: "2"
      }),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLandCycles('triple'), (cycle) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "cycleItem",
            key: cycle
          }, [
            (cycle.color==='triple')
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: '/cycles/'+cycle.key,
                  replace: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: "coverArt",
                      src: cycle.coverArt
                    }, null, 8, _hoisted_8),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(cycle.display), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}