
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Divider',
    props: {
      text: String,
      subText: String,
      level: String,
    },
    components: {
    },
    data() {
      return {

      }
    },
    computed: {

    },
    methods: {

    }
  });
