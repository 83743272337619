
  import { defineComponent } from 'vue';
  import { state, actions } from "../store";


  export default defineComponent({
    name: 'PrintingSelect',
    props: {
    },
    components: {
    },
    data() {
      return {
        state: state
      }
    },
    computed: {

    },
    methods: {
      setPrinting(e:Event) {
        actions.setPreferredPrinting((e.target as HTMLSelectElement).value as string);
      }
    }
  });
