import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "Divider font--fancy" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "subHeading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.level==='2')
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString(_ctx.text), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.level==='3')
      ? (_openBlock(), _createElementBlock("h3", _hoisted_3, [
          _createElementVNode("div", null, _toDisplayString(_ctx.text), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.subText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.subText), 1))
      : _createCommentVNode("", true)
  ]))
}