
  import { defineComponent } from 'vue';
  import { state, actions } from "../store";
  import { SearchType } from '@/classes/scryfallConnector';
  import { LandCycleName } from '@/classes/utils';
  import ColorPip from '@/components/ColorPip.vue';

  export default defineComponent({
    name: 'Filter',
    props: {
      name: String,
      searchType: String,
      colorList: String,
      type: String,
    },
    components: {
      ColorPip
    },
    data() {
      return {
        state: state,
      }
    },
    methods: {
      toggle: function() {
        if(!this.type) {
          actions.toggleSearch((this.searchType as unknown) as SearchType);
        } else if(this.type === 'landCycle') {
          actions.toggleCycle((this.searchType as unknown) as LandCycleName);
        }        
      },

      isActive: function() {
        if(!this.type) {
          return state.connector.filters.search.has(this.searchType as any);
        } else if(this.type === 'landCycle') {
          return state.connector.filters.cycle.has(this.searchType as any);
        }

        return false;
      }
    }
  });
