
  import { defineComponent } from 'vue';
  import { state } from "../store";


  export default defineComponent({
    name: 'Select',
    props: {
      label: String,
      optionList: Array,
      callback: Function
    },
    components: {
    },
    data() {
      return {
        state: state
      }
    },
    computed: {

    },
    methods: {
      setValue(e:Event) {
        if(this.callback) {
          this.callback((e.target as HTMLSelectElement).value as string);
        }
        
        //actions.setPreferredPrinting((e.target as HTMLSelectElement).value as string);
      }
    }
  });
