
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue';
import router from '@/router';
import { state, actions, getManaLists } from "../store";

export default defineComponent({
  name: 'ManaLists',
  data() {
    return {
      state: state
    }
  },
  components: {
    Button
  },

  computed: {
    manaLists() {
      return getManaLists()
    }
  },
  methods: {
    goToNewList: function() {
      actions.createManaList();
      if(state.currentManaList) {
        router.push(`/lists/${state.currentManaList.id}`);
      } 
    }
  }
});
