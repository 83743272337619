
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Button',
    props: {
      text: String,
      icon: String,
      type: String,
      size: String
    },
    components: {
    },
    data() {
      return {

      }
    },
    computed: {

    },
    methods: {

    }
  });
