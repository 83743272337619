
import { defineComponent } from 'vue';
import { ScryfallCard } from '@/classes/scryfallCard';
import Divider from '@/components/Divider.vue';
import { actions, state } from "../store";

export default defineComponent({
  name: 'Cycles',
  data() {
    return {
      state: state
    }
  },
  components: {
    Divider,
  },
  mounted: function() {
    actions.setScrollTop();
  },
  computed: {
    cycleList: () => {
      const cardMap = new Map();
      const cycleMap = new Map();
      [...state.connector.allCards.values()].forEach((item) => {
        const key = item?.cycle?.name;
        if(key) {
          const collection = cardMap.get(key);
          if (!collection) {
             cardMap.set(key, [item]);
          } else {
             collection.push(item);
          }
        }
      });

      [...cardMap.values()].forEach(cycleList => cycleList.sort((a:ScryfallCard, b:ScryfallCard) => a.color_identity.join('').localeCompare(b.color_identity.join(''))).reverse());

      let coverCounter = 0;
      for(const key of [...cardMap.keys()]) {
        
        const cards = cardMap.get(key);
        const avgEdhRec = Math.floor(cards.reduce((prev:number, cur:ScryfallCard) => prev + cur.edhrec_rank, 0) / cards.length);
        const avgPrice = (cards.reduce((prev:number, cur:ScryfallCard) => prev + cur.getPrinting('cheapest').price_usd, 0) / cards.length).toFixed(2);
        console.log(key, avgEdhRec, avgPrice);

        // Build the cycle object
        cycleMap.set(key, {
          key: key,
          display: cardMap.get(key)[0].cycle.display,
          color: cardMap.get(key)[0].cycle.color,
          cards: cardMap.get(key),
          coverArt: cardMap.get(key)[coverCounter].printings[0].cover_art[0],
          avgEdhRec,
          avgPrice
        });

        coverCounter = (coverCounter + 1) % 5
      }
      console.log(cycleMap);
      return cycleMap;
    },
  },
  methods: {
    getLandCycles: function(name:string) {
      
      const ret = [...this.cycleList.values()].filter(x => x.color===name).sort((a, b) => a.display.localeCompare(b.display));

      console.log("EHY");
      console.log(ret);

      return ret;
    }
  }
});
