
import { defineComponent } from 'vue';
import { actions, state } from "../store";

import Button from '@/components/Button.vue';
import Filter from '@/components/Filter.vue';
import SearchResults from '@/components/SearchResults.vue';
import PriceControl from '@/components/PriceControl.vue';
import QuickScroll from '@/components/QuickScroll.vue';
//import Selections from '@/components/Selections.vue';
import ColorButton from '@/components/ColorButton.vue';
import Divider from '@/components/Divider.vue';


import { SearchType } from '@/classes/scryfallConnector';
import { landCycleList, LandCycle } from '@/classes/utils';


export default defineComponent({
  name: 'Home',
  components: {
    Button,
    Divider,
    Filter,
    SearchResults,
    QuickScroll,
    //Selections,
    PriceControl,
    ColorButton
  },
  data() {
    return {
      state: state,
      SearchType: SearchType,
      landCycleList: landCycleList
    }
  },
  mounted: function() {
    actions.setQueryParams();
    actions.setScrollTop();
  },
  methods: {
    doSearch: function() {
      actions.doQuery();
    },
    clearFilter: function() {
      actions.clearFilters();
    },
    setAdvancedSearch: function() {
      actions.setAdvancedSearch(!state.advancedSearch);
    },
    setColorless: function() {
      actions.setColorFilter('C');
    },
    setUntapped: function() {
      actions.setUntappedFilter(!state.connector.filters.untapped);
    },
    isInit: function() {
      return this.state.connector.filters.color.length === 0;
    },
    setProductionFilter: function() {
      actions.toggleSearch(SearchType.Produces);
      actions.doQuery();
    },
    setUtilityFilter: function() {
      actions.toggleSearch(SearchType.Utility);
      actions.doQuery();
    }
  },
  computed: {
    sortedCycleList: () => {
      let sortedList = landCycleList.sort((a:LandCycle, b:LandCycle) => {
        if(a.name < b.name) {
          return -1;
        } else if(a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });

      return sortedList;
      //return sortedList.filter(x => !(x.color === 'triple' && state?.connector?.filters?.color.length < 3))
    }
  }
});
